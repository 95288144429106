import React from 'react'
import PropTypes from 'prop-types'

import { Image } from '@patomation/react-ui-components'
import logo from '../images/logo.png'

const Logo = ({maxWidth, style}) => {
  return (
    <Image
      style={{
        maxWidth,
        ...style
      }}
      src={logo} />
  )
}

Logo.propTypes = {
  maxWidth: PropTypes.string,
  style: PropTypes.object
}

Logo.defaultProps = {
  maxWidth: '150px'
}

export default Logo
