import React, { useEffect } from 'react'

const TalkTo = () => {

  useEffect(() => {
    var s1 = document.createElement("script"),
        s0=document.getElementsByTagName("script")[0]

    s1.async=true
    s1.src='https://embed.tawk.to/5dda3838d96992700fc8f41f/default'
    s1.charset='UTF-8'
    s1.setAttribute('crossorigin','*')
    s0.parentNode.insertBefore(s1,s0)
  })

  return (
    <div className='talkto__script-loader'></div>
  )
}

export default TalkTo
