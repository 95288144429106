import React from 'react'
import { Footer, Grid, Gutter, Image, List } from '@patomation/react-ui-components'
import { Link } from 'gatsby'

import tripAdvisor from '../images/trip-advisor.png'
import departmentOfTourism from '../images/department-of-tourism.png'
import Logo from './Logo'

const FooterBar = () =>
<Footer background='#86AC41' color='#324851'>
  <Grid className='footer__grid' col={6} gap='1rem' style={{justifyItems: 'top'}}>

    <div
      className='footer__col1'
      style={{
        textAlign: 'center',
        gridColumn: 'auto / span 2'
      }}>

      <div>
        <Logo style={{margin: '0 auto'}} />
      </div>
      <Gutter/>

      <div className='footer__social'>
        <a href="line://oaMessage/lannabanana">
          <i className="fab fa-line"/><Gutter vertical />
        </a>
        <a href="https://wa.me/6681021178">
          <i className="fab fa-whatsapp-square"/><Gutter vertical />
        </a>
        <a href="mailto:info@privateguidechiangmai.com">
          <i className="fas fa-envelope-square"/><Gutter vertical />
        </a>
        <a href="tel:+66 811336814">
          <i className="fas fa-phone-square"/><Gutter vertical />
        </a>
        <a href="skype:patchara3600?chat">
          <i className="fab fa-skype"/>
        </a>
      </div>

      <Gutter/>
      <div style={{fontSize:'0.8rem'}}>© Norhtern Thai Escapes Ltd. 2019</div>

    </div>

    <div className='footer__col2'>
      <List
        data={[
          <Link to='/contact'>Contact</Link>,
          <Link to='/packages'>Tour Packages</Link>,
          <Link to='/reviews'>Reviews</Link>,
          <Link to='/policy'>Company policy</Link>,
        ]}
      />
    </div>

    <div className='footer__col3'>
      <Image
        style={{maxWidth:'100px', margin: '0 auto'}}
        src={departmentOfTourism} />
    </div>

    <div
      className='footer__col4'
      style={{
        gridColumn: '5 / span 2'
      }}>


        <Image src={tripAdvisor} style={{maxWidth:'100px', margin: '0 auto'}} />
        <Gutter />

        <div style={{
          fontSize:'0.75rem'
        }}>
          We accept
        </div>
        <div
          className='footer__payment_options'
          style={{
            fontSize: '2rem'
          }}>
            <i className="fab fa-cc-visa"/><Gutter vertical />
            <i className="fab fa-cc-mastercard"/><Gutter vertical />
            <i className="fab fa-cc-paypal"/>
        </div>

    </div>

  </Grid>
</Footer>


export default FooterBar
